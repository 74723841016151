import React, { useEffect } from 'react';
import Layout from "../components/layout"
import * as sections from "../components/sections"
import { LandingData } from '../content/mainContent';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics , logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA3o1QyLizaL4ax4Ma9Pp61R3_AEXkMwv8",
  authDomain: "zeta-rush-295408.firebaseapp.com",
  projectId: "zeta-rush-295408",
  storageBucket: "zeta-rush-295408.appspot.com",
  messagingSenderId: "234701837136",
  appId: "1:234701837136:web:eabfdc10595e4a1eddd255",
  measurementId: "G-J3BX5NLQER"
};

let analytics = null;
if (typeof window !== 'undefined') {
  const app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
}


//console.log("LandingData:", LandingData )


// if inline json:
//const { homepage } = landingData.data
// console.log("landingData:",landingData)
// else:
const { homepage } = LandingData.data
// console.log("homepage:",homepage)


const IndexPage = () => {
  const analyticsProps = { analytics: analytics };
  return (
      <Layout {...homepage}>

        {homepage.blocks.map((block) => {
          const { id, blocktype, ...componentProps } = block
          const Component = sections[blocktype]
          const propsWithFirebaseAnalytics = { ...analyticsProps, ...componentProps };
          return <Component key={id} {...propsWithFirebaseAnalytics} />
        })}


      </Layout>
  )  
}
export default IndexPage
// export default ;
