import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import {
  Box,
  ButtonList,
  Container,
  Flex,
  Section,
  Kicker,
  SuperHeading,
  SubheadSmall,
  Text,
} from "./ui"
// RH customisation
import { heroImgBoxMargin, heroH2Adjust } from "../tailor.module.css"
import { logEvent } from "firebase/analytics"


export default function Hero(props) {
  const handleClick = (event) => {
    // Log the event to Firebase Analytics
    logEvent(props.analytics, 'hero_button_clicked');
  }
  return (
    <Section>
      <Container>
        <Flex gap={4} variant="spaceBetween">
          <Box width="half" gutter={1} id="top" >
            <SuperHeading as="h1">
              {props.kicker && <Kicker>{props.kicker}</Kicker>}
              {props.h1}
            </SuperHeading>
            <SubheadSmall className={heroH2Adjust}>
              {props.subhead}
            </SubheadSmall>
            <Text as="p">{props.text}</Text>
            <ButtonList variant = "active" links={props.links} onClick={handleClick}/>
          </Box>
          <Box width="third" className={heroImgBoxMargin}>

            <StaticImage 
              src="../images/mf_LP_02.png" alt="moveflow tech hero image" placeholder="blurred" />

          </Box>
        </Flex>
      </Container>
    </Section>
  )
}
