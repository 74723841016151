import * as React from "react"
import {
  Container,
  Section,
  FlexList,
  Text,
  Kicker,
  Heading,
  Subhead, SubheadSmaller,
  Box,
  LinkList,
  Space,
} from "./ui"
import { textLeft, boxMargin, } from "../tailor.module.css"

// different icons are available to choose
//import { ReactComponent as Icon1 } from "../icons/international_gatsby.svg"
import { ReactComponent as Icon1 } from "../icons/peopleMap03_gatsby.svg"
//import { ReactComponent as Icon1 } from "../icons/peopleMap02_gatsby.svg"
//import { ReactComponent as Icon1 } from "../icons/peopleMap01_gatsby.svg"
//import { ReactComponent as Icon1 } from "../icons/tracking01_gatsby.svg"


//import { ReactComponent as Icon2 } from "../icons/locator01_gatsby.svg"
import { ReactComponent as Icon2 } from "../icons/tracking01_gatsby.svg"


function TechSection(props) {
  // console.log("TechSection:",props)

  return (
    <Box center>
      
      {props.iconSet === 0 ?  <Icon1 /> : <Icon2 /> }

      <Space size={3} />

      <Subhead>{props.heading}</Subhead>
      <Text>{props.text}</Text>
      <LinkList links={props.links} />
      {props.children.map((data, index) => (
          <Text key={index} as="p" variant="lead" className={textLeft} >{data.textContent}</Text>
      ))}
    </Box>
  )
}

export default function TechList(props) {
  // console.log("* * * TechList:",props.content)
  return (
    <Section id="technology">
      <Container>
        <Box>
          <Box center paddingY={1}>
            <Heading>
              {props.kicker && <Kicker>{props.kicker}</Kicker>}
              {props.heading}
            </Heading>
            <SubheadSmaller width="threequarter" style={{ paddingBottom: "50px" }} >
              {props.text}
            </SubheadSmaller>
          </Box>
        </Box>
        <FlexList gap={1} variant="start" responsive>
          {props.content.map((details, i) => (
            <li key={details.id} className={boxMargin} >
              <TechSection {...details} iconSet={i} />
            </li>
          ))}
        </FlexList>      
      </Container>
    </Section>
  )
}
