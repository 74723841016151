import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import  "../styles/feature-one-carousel-swiper.scss"


// import required modules
import { EffectCoverflow, Pagination, Autoplay } from "swiper";

import slide01 from "../images/01_strength.png"
import slide02 from "../images/02_weakness.png"
import slide03 from "../images/03_opportunity.png"
import slide04 from "../images/04_threat.png"

export default function App() {
/*
        autoplay={{
          delay: 100000,
          reverseDirection: true
        }}
*/  
  return (
    <>
      <Swiper
        autoplay={{
          delay: 2500,
          reverseDirection: false
        }}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        loop={true}
        loopedSlides={2}
        initialSlide={1}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}

        modules={[EffectCoverflow, Pagination, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={slide01} alt="example 1" />
          <div className="cPos">
            <h2>Strength</h2>
            <p>Store located between quarters and major rail hub. Ideal location fo commuters.</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide02} alt="example 2" />
          <div className="cPos">
            <h2>Weakness</h2>
            <p>Store reachable only if commuters make a deliberate detour.</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide03} alt="example 3" />    
          <div className="cPos">
            <h2>Opportunity</h2>
            <p>Choose the best location when looking for business expansion.</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide04} alt="example 4" />
          <div className="cPos">
            <h2>Threat</h2>
            <p>Planning a new streetcar line will attract many current customers.</p>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

/*
export default function App() {
  return (
    <>
      <Swiper
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={slide01} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide02} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide03} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide04} />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
*/