//-
// Build json of content model expected by theme:
// i.e. it mimics the response of the graphql request from CMS
// Files containing additional data models:
//   header.js for Navbar
//   footer.js 
// Note: the order of the blocks in the json determines the order of the sections on the web page. The order of 
// how blocks are mentioned in sections.js do not play a role.
//-
export const LandingData = {
    "data": {
        "homepage": {
            "id": "j65234",
            "title": "Moveflow",
            "description": "At Moveflow, we understand that the world is constantly changing and evolving. That's why we provide location-specific predictions to help businesses adapt and evolve. With our powerful insights, you can make better business decisions that lead to the discovery of new opportunities.",
            "blocks": [
                {
                    "id": "a34722",
                    "blocktype": "HomepageHero",
                    "kicker": "",
                    "subhead": "The future may seem unpredictable, but we are here to help. " +
                        "Our technology understands human behavior, such as when and where people shop, spend their leisure time, and commute.  " +
                        "This allows us to provide reliable predictions for your business, helping you plan for the future with confidence. " +
                        "Trust Moveflow to give you the tools you need to succeed.",
                    "h1": "Unlock the future with Moveflow",
                    "links": [
                        {
                            "id": "a34723",
                            "href": "https://dashboard.moveflow.tech",
                            "text": "Try our demo",
                        }
                    ]
                },

                {
                    "id": "b88434",
                    "blocktype": "HomepageFeatureList",
                    "kicker": "",
                    "heading": "Location intelligence to accelerate your business",
                    "text": "Moveflow provides your business a valuable edge in a challenging and changing environment.",
                    "content": [
                        {
                            "id": "f6582a",
                            "kicker": "Recommendations for action",
                            "heading": "Let's act now.",
                            "text": "Some content text via json",
                            "idx": 0,
                            "children": [
                                {
                                    "id": "f65821",
                                    "textContent": "At Moveflow, we understand that the world is constantly changing and evolving. That's why we provide location-specific predictions to help businesses adapt and evolve. With our powerful insights, you can make better business decisions that lead to the discovery of new opportunities."
                                },
                                {
                                    "id": "f65822",
                                    "textContent": "Moveflow empowers managing your company's future, finding your strengths and weaknesses, overcoming problems, and seizing opportunities. " +
                                        "Take control of your future with Moveflow."
                                }
                            ],
                            "image": {
                                "id": "f6582i",
                                "alt": "SWOT analysis",
                                "gatsbyImageData": {
                                    "images": {
                                        "sources": [
                                            {
                                                "sizes": "100px",
                                                "srcSet": "https://sihub.de/brz5kk96e88465-86bndbq6/email-icon.png",
                                                "type": "image/png",
                                            }
                                        ],
                                    }, // images
                                    "layout": "constrained",
                                    "width": 60,
                                    "height": 30,
                                    "backgroundColor": "#b8e8c8"
                                }, // gatsbyImageData
                            }, // image
                        }, // content
                        {
                            "id": "f6582b",
                            "kicker": "Resilience",
                            "heading": "It is easy to prepare yourself.",
                            "text": "Content text",
                            "idx": 1,
                            "children": [
                                {
                                    "id": "bbb43",
                                    "textContent": "Anything can happen at any time. Moveflow helps you understand how your business can behave in different situations and thus protects you from risks affecting your services and products."
                                },
                                {
                                    "id": "bbb44",
                                    "textContent": "Moveflow provides you with a suite of tools to analyze the performance of your business against a variety of what-if scenarios. Preparing for what might come helps you to excel, succeed, and profit."
                                }
                            ], // children
                            "image": {
                                "id": "f6582ii",
                                "alt": "Be resilient",

                                "gatsbyImageData": {
                                    "src": "../images/toronto01.png",
                                    "layout": "constrained",
                                    "width": 300,
                                    "height": 300,
                                    "backgroundColor": "#b8e8c8",
                                }, // gatsbyImageData

                            }, // image 2
                        },
                        {
                            "id": "f6582c",
                            "kicker": "Planning",
                            "heading": "Know what matters",
                            "text": "Some content text via json",
                            "idx": 2,
                            "children": [
                                {
                                    "id": "43t4",
                                    "textContent": "Data quality significantly determines the correct valuation of objects and reliable recommendations for action."
                                },
                                {
                                    "id": "sdvdbg",
                                    "textContent": "With our Customer Preferences Monitor, you act proactively in property management and at the same speed as your data pool change in the daily business."
                                },
                                {
                                    "id": "gf7abf",
                                    "textContent": "Gapless data sets, spatially highly resolved, time-referenced, attributed. We provide accurate short, medium, and long-term forecasts for different time horizons."
                                },
                            ],
                            "image": {
                                "id": "f6582iii",
                                "alt": "Information that matters",
                                "gatsbyImageData": {
                                    "images": {
                                        "sources": [
                                            {
                                                "sizes": "1000px",
                                                "srcSet": "https://placeimg.com/640/480/any",
                                                "type": "image/png"
                                            }
                                        ],
                                    }, // images
                                    "layout": "constrained",
                                    "width": 600,
                                    "height": 300,
                                    "backgroundColor": "#b8e8c8",
                                }, // gatsbyImageData
                            }, // image 3
                        },
                        {
                            "id": "f6582d",
                            "kicker": "Optimization",
                            "heading": "Use your data properly ",
                            "text": "Some content text via json",
                            "idx": 3,
                            "children": [
                                {
                                    "id": "mtmt3",
                                    "textContent": "We put your data about the target audience and location in context. By understanding the relationship between location and target audience, you can make informed resource planning decisions and drive growth for your business."
                                },
                                {
                                    "id": "mghmgm",
                                    "textContent": " Our Moveflow products provide the insights you need to make data-driven decisions."
                                }
                            ],
                            "image": {
                                "id": "f6582iv",
                                "alt": "Optimization in a complex world",
                                "gatsbyImageData": {
                                    "images": {
                                        "sources": [
                                            {
                                                "sizes": "1000px",
                                                "srcSet": "https://placeimg.com/640/480/any",
                                                "type": "image/png"
                                            }
                                        ],
                                    }, // images
                                    "layout": "constrained",
                                    "width": 600,
                                    "height": 300,
                                    "backgroundColor": "#b8e8c8",
                                }, // gatsbyImageData
                            }, // image 4
                        },
                        {
                            "id": "f6582e",
                            "kicker": "Preferences",
                            "heading": "Learn about your customers",
                            "text": "Some content text via json",
                            "idx": 4,
                            "children": [
                                {
                                    "id": "htrh",
                                    "textContent": "Keep all the aforementioned in mind and gain business advantages by knowing your customers' preferences."
                                },
                                {
                                    "id": "dhrtwzh",
                                    "textContent": "Through a more detailed and sophisticated understanding of human behavior, you will understand how retail space can be designed and utilized more optimally."
                                },
                                {
                                    "id": "dhrtwzh",
                                    "textContent": "In addition, our location intelligence products will help you determine what purchasing power is available at the point of interest."
                                }
                            ],
                            "image": {
                                "id": "f6582v",
                                "alt": "Knowing your customers preferences",
                                "gatsbyImageData": {
                                    "images": {
                                        "sources": [
                                            {
                                                "sizes": "1000px",
                                                "srcSet": "https://placeimg.com/640/480/any",
                                                "type": "image/png"
                                            }
                                        ],
                                    }, // images
                                    "layout": "constrained",
                                    "width": 600,
                                    "height": 300,
                                    "backgroundColor": "#b8e8c8",
                                }, // gatsbyImageData
                            }, // image 5
                        } // last content item
                    ]     // content
                }, // HomepageFeatureList                
                {
                    "id": "c54782",
                    "blocktype": "HomepageCta",
                    "kicker": "Contact us",
                    "heading": "Gain your competitive edge!",
                    "text": "Time is precious in a rapidly changing world. Call us today, and we will demonstrate how Moveflow will accelerate your business.",
                    "links": [
                        {
                            "id": "c51ahh",
                            "href": "mailto:info@moveflow.tech",
                            "text": "Request a demo"
                        }
                    ]
                },

                {
                    "id": "c65839",
                    "blocktype": "HomepagePricing",
                    "kicker": "",
                    "heading": "Pricing",
                    "text": "Take advantage of Moveflow's location intelligence technology with our flexible pricing options. See how we can benefit your business today.",
                    "links": [
                        {
                            "id": "c65669",
                            "href": "/pricing/",
                            "text": "View plans"
                        }
                    ]
                }, // Pricing


                {
                    "id": "d98401",
                    "blocktype": "HomepageTechList",
                    "kicker": "Technology",
                    "heading": "The state of the art",
                    "text": "Moveflow is powered by cutting-edge technology backed by years of research and proven results in the field.",
                    "content": [
                        {
                            "id": "bb8843",
                            "heading": "How it works",
                            "text": "",
                            "children": [
                                {
                                    "id": "bbb8841",
                                    "textContent": "Our method of deriving user behavior from a small group of volunteering individuals involves mathematical and numerical modeling. This allows us to make precise statements about the movement of an entire population in terms of their daily journeys."
                                },
                                {
                                    "id": "bbb8842",
                                    "textContent": "We use state-of-the-art AI models, intelligent agents, semantic contextual information, and spatial reasoning to create a digital reflection of the real physical world, which we call the Moveflow Simulation."
                                }
                            ]
                        },
                        {
                            "id": "bbbbb",
                            "heading": "The Moveflow Simulation",
                            "text": "",
                            "children": [
                                {
                                    "id": "bbb43",
                                    "textContent": "The Moveflow Simulation is based on the observations and analysis of a small group of real people and their activities at a specific location. The simulation also incorporates location-specific current events and dynamic environmental changes."
                                },
                                {
                                    "id": "bbb44",
                                    "textContent": "This allows it to accurately predict the current and expected future whereabouts of all individuals, enabling users to understand the current status quo and align their decisions with a future outcome. By providing a detailed reflection of the real world, the Moveflow Simulation helps our clients to make better decisions."
                                }
                            ]
                        }

                    ]
                } // HomepageTechList
            ] // blocks
        } // homepage
    } // data
};