import * as React from "react"
import {
  Container,
  Section,
  Heading,
  SubheadSmaller,
  Box,
  Space,
  ButtonList,
} from "./ui"

import { logEvent } from "firebase/analytics";

export default function PricingList(props) {
  //console.log("* * * PricingList:",props.links)
  const handleClick = (event) => {
    // Log the event to Firebase Analytics
    logEvent(props.analytics, 'pricing_button_clicked');
  }
  return (
    <Section id="pricing">
      <Container>

          <Box center paddingY={1}>

            <Heading>
              {props.heading}
            </Heading>
            {props.text && 
              <SubheadSmaller width="threequarter" >{props.text}</SubheadSmaller>}
            <Space size={2} />
            <ButtonList links={props.links} variant="center"  
              onClick={handleClick} />


          </Box>
  
      </Container>
    </Section>
  )
}