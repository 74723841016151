import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { 
  Container, 
  Section,
  Flex,
  Box, 
  Kicker, 
  Heading, 
  Subhead,
  SubheadSmaller,
  Text } from "./ui"
import CarouselSwiper from "./feature-one-carousel-swiper"
import { useSiteMetadata } from "../hooks/feature-images"
import { fluentImageWrapper } from "../tailor.module.css"

let count = 0

// note: its also possible to serve 'alt' by props.image.alt or so (to be defined properly in index.js content model)
function FeatureSection(props) {
  // console.log("FeatureSection:",props)
  // const bild = getImage(props.image.gatsbyImageData)
  // console.log("FeatureSection, bild:",bild)
  // console.log("FeatureSection, bild:",props.image.gatsbyImageData)

//  const ArtistsPage = ({ artists }) => {
//    const bild1 = getImage(artists.allArtistsCard.nodes)
//    console.log("FeatureSection, bild1:",bild1)
//  }

  //- if allImageSharp is queried:
  //const { nodes } = useSiteMetadata()
  //console.log("feature-list, nodes:",nodes[0].lastName)
  //console.log("feature-list, nodes",nodes)
  //- if allFile (sorted) is queried:
  const { edges } = useSiteMetadata()
  //console.log("feature-list, edges.node.childImageSharp",edges)

  count++

  return (
    <Section padding={4} background="muted">
      <Container>
        <Flex gap={4} variant="responsive" style= {{ alignItems: "flex-start" }} >
          <Box width="half" order={props.flip ? 1 : null}>
            {count === 1 ? <CarouselSwiper /> :edges[props.idx].node.childImageSharp.gatsbyImageData && (
              <GatsbyImage className={ fluentImageWrapper }  alt={edges[props.idx].node.id}
                image={getImage(edges[props.idx].node.childImageSharp.gatsbyImageData) }
              />
            )}
          </Box>

          <Box width="half">

            <Subhead>
              {props.kicker && <Kicker>{props.kicker}</Kicker>}
              {props.heading}
            </Subhead>
            {props.children.map((data, index) => (
              <Text key={index} as="p" variant="lead" >{data.textContent}</Text>
            ))}
          </Box>
        </Flex>
      </Container>
    </Section>

  )
}

// keep padding 4 since this is used by media settings too
export default function FeatureList(props) {
  return (
    <Container width="fullbleed" id="features">
      <Box center background="muted" radius="large">
        <Box center paddingY={5} padding={4} width="eighty" >
          <Heading>
            {props.kicker && <Kicker>{props.kicker}</Kicker>}
            {props.heading}
          </Heading>
          {props.text && 
            <SubheadSmaller width="threequarter" >{props.text}</SubheadSmaller>}
        </Box>

        {props.content.map((feature, i) => (
          <FeatureSection key={feature.id} {...feature} flip={Boolean(i % 2)} />
        ))}        
      </Box>
    </Container>
  )
}

