// https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
import { useStaticQuery, graphql } from "gatsby"

// Method - Advantage
// This routine allows that the performant Gatsby Image component can be used for the 
// different images of the features section, just as if the image content would be 
// delivered by a CMS. This makes it possible that the alternating right, left scrolling 
// in features can be realized with little code.
//
// Method - Disadvantage
// Since no image node for local files is currently available (that needs to be 
// setted up in gatsby-node.js), here we use "gatsby-plugin-sharp" capabilities
// to fetch the whole content of a single folder ("gatsby-source-filesystem") in order to serve 
// images requested by the Features section of site.  
// gatsby-source-filesystem can still be used to provide content in other website areas 
// by using other folders (https://www.gatsbyjs.com/plugins/gatsby-source-filesystem/?=transformer#how-to-use). 
// Also, all other static image imports must specify the relative path directly (see CTA). 
// This may cause performance problems in the long run.  
//
// "site" is only fetched to check that query works properly
// refactor: remove 'site'
export const useSiteMetadata = () => {
  const { allFile } = useStaticQuery(
    graphql`
		query {
		    site {
		      siteMetadata {
		        title 
		      }
		    }
			  allFile(sort: {fields: base}) {
			    edges {
			      node {
			        id
			        base
			        childImageSharp {
			          gatsbyImageData
			        }
			      }
			    }
			  }	    
		}
    `
    )
  // console.log("feature-images, site:",site)
  // console.log("feature-images, allFile:",allFile)
  return allFile
}

{/*
  const { allImageSharp } = useStaticQuery(
    graphql`
		query {
		    site {
		      siteMetadata {
		        title 
		      }
		    }
		    allImageSharp {
		      nodes {
		        id
						gatsbyImageData(formats: NO_CHANGE)		        
		      }
		    }		    
		}
  console.log("feature-images, allImageSharp:",allImageSharp)
  return allImageSharp
*/}