import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
//  Nudge,
  Container,
  Section,
  Heading, 
  SubheadSmaller,
  ButtonList,
  Kicker,
  Box,
} from "./ui"

import { logEvent } from "firebase/analytics";

export default function HomepageCta(props) {
  const handleClick = (event) => {
    // Log the event to Firebase Analytics
    logEvent(props.analytics, 'cta_button_clicked');
  }
  return (
    <Container width="fullbleed" id="contact">
      <Section padding={5} radius="large" background="active">
        <Box center >
          <Heading center>
            {props.kicker && <Kicker center>{props.kicker}</Kicker>}
            {props.heading}
          </Heading>
          <SubheadSmaller width="threequarter" style={{ paddingBottom: "50px" }} >
            {props.text}
          </SubheadSmaller>
        </Box>

        <Box center style={{ paddingTop: "30px" }} >
          <StaticImage  src="../images/build_CTA_02.png" alt="moveflow cta image" width={500} placeholder="blurred" />
        </Box>
        <ButtonList links={props.links} variant="center" reversed style={{ paddingTop: "50px" }} onClick={handleClick} />


      </Section>
    </Container>
  )
}